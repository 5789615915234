import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';

@Component({
    selector: 'app-contact-details-readonly',
    template: `
        <div>
            <span
                >{{ moveSandbox.firstName$ | async }} {{ moveSandbox.lastName$ | async }}
                <span *ngIf="!!(moveSandbox.gender$ | async)">({{ (moveSandbox.gender$ | async)?.toUpperCase() | translate }})</span></span
            >
        </div>
        <div>
            <span>{{ moveSandbox.phoneNumber$ | async }}</span>
        </div>
        <div>
            <span>{{ moveSandbox.email$ | async }}</span>
        </div>
        <div *ngIf="showNationalNumber" class="u-margin-top">
            <span>{{ 'MOVE.USER.NATIONAL_NUMBER.SHORT' | translate }}: {{ (moveSandbox.user$ | async)?.nationalNumber }}</span>
        </div>
    `,
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactDetailsReadonlyComponent {
    @Input() showNationalNumber = false;
    @Input() showPassportNumber = false;

    constructor(public moveSandbox: MoveSandbox) {}
}
