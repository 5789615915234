import { cacheBusterHash } from '@smooved/core';

export const environment = {
    production: true,
    environmentName: 'staging',
    initialData: false,
    appUri: 'https://app-staging.smooved.be',
    serverUri: 'https://api-staging.smooved.be',
    apiUri: 'https://api-staging.smooved.be/api/v1',
    faqUri: 'https://smooved.be/faq',
    termsAndConditionsUri: {
        nl: 'https://www.smooved.be/algemene-voorwaarden/',
        fr: 'https://www.smooved.be/algemene-voorwaarden/',
        en: 'https://www.smooved.be/algemene-voorwaarden/',
    },
    privacyUri: {
        nl: 'https://www.smooved.be/privacy/',
        fr: 'https://www.smooved.be/privacy/',
        en: 'https://www.smooved.be/privacy/',
    },
    widgetUri: 'https://widgets-staging.smooved.be',
    widgetBootstrapScript: 'https://widgets-staging.smooved.be/bootstrap/v2.bootstrap.min.js',
    widgetBootstrapName: 'v2.bootstrap-staging',
    services: {
        telecom: true,
        energy: true,
        water: true,
        insurance: true,
        logistics: true,
        post: true,
        iots: false,
    },
    agreementUri: 'https://smooved-agreements-staging.netlify.com',
    featureFlags: {
        nps: true,
    },
    showTestWebhook: false,
    showBeamer: true,
    mockFormData: {
        mortgage: null,
        leadPassingWithoutLeaver: null,
        leadPassingWithLeaver: null,
        createRealEstateAgent: false,
    },
    externalInfluencerUri: 'https://influencers-staging.smooved.be',
    telecomBannerUri: 'https://smooved-commercial.s3.eu-west-3.amazonaws.com/telecom/banner.png',
    telecomBannerFlashUri: 'https://smooved-commercial.s3.eu-west-3.amazonaws.com/telecom/banner-flash.png',
    telecomBannerFlash: true,
    cacheBuster: cacheBusterHash,
    houseMatchUri: 'http://app-staging.housematch.be',
    houseMatchCompanyPageUri: 'http://app-staging.housematch.be',
    insurancesPartner: 'Yago',
    packageInfoUri: {
        nl: 'https://smooved.be/pakketten/',
        fr: 'https://smooved.be/fr/formules/',
        en: 'https://smooved.be/pakketten/',
    },
    mailTo: {
        nl: 'verhuizen@smooved.be',
        fr: 'demenager@smooved.be',
        en: 'verhuizen@smooved.be',
    },
    featureToggle: {
        smoovedCompanion: true,
    },
};
