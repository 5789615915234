import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-family-size',
    template: `
        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
            <app-number-input
                [formControlName]="'childrenCount'"
                [label]="'MOVE.FAMILY_SIZE.CHILDREN' | translate"
                [hasMargin]="true"
                [hasMarginDouble]="false"
            ></app-number-input>

            <app-number-input
                [formControlName]="'teenagersCount'"
                [label]="'MOVE.FAMILY_SIZE.TEENAGERS' | translate"
                [hasMargin]="true"
                [hasMarginDouble]="false"
            ></app-number-input>

            <app-number-input
                [formControlName]="'adultsCount'"
                [label]="'MOVE.FAMILY_SIZE.ADULTS' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
            ></app-number-input>

            <app-previous-submit (previous)="previous.emit()"></app-previous-submit>
        </form>
    `,
    styles: [
        `
            :host {
                display: block;
                width: 100%;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FamilySizeComponent implements OnInit, OnDestroy {
    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public next: EventEmitter<void> = new EventEmitter<void>();

    public form: UntypedFormGroup = this.formBuilder.group({
        childrenCount: [0, Validators.required],
        teenagersCount: [0, Validators.required],
        adultsCount: [1, Validators.required],
    });

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.familyMemberCountOnce$.subscribe((familyMemberCount) => {
            if (familyMemberCount) {
                this.form.patchValue(familyMemberCount);
            }
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.next.emit();
        }
    }

    public ngOnDestroy(): void {
        this.moveSandbox.patchProperty('meta.familyMemberCount', this.form.value);
    }
}
