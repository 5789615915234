import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { UserNameComponent } from '@app/shared/components/user-name/user-name.component';

@Component({
    selector: 'app-table-column-formatted-name',
    template: `
        <ng-container matColumnDef="name">
            <th mat-header-cell class="__cell u-padding-left u-padding-right" *matHeaderCellDef>{{ 'NAME' | translate }}</th>
            <td mat-cell *matCellDef="let row" [title]="userName?.name" class="__cell u-padding-left u-padding-right">
                <app-user-name [user]="row.user"></app-user-name>
            </td>
        </ng-container>
    `,
    styles: [
        `
            .__cell {
                width: 250px;
            }
        `,
    ],
})
export class TableColumnFormattedNameComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;
    @ViewChild(UserNameComponent) userName: UserNameComponent;

    constructor(@Optional() public table: MatTable<any>, private cdRef: ChangeDetectorRef) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }
}
