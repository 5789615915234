import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-image-modal',
    template: `
        <app-closable-modal-template maxWidth="auto">
            <img [src]="data" />
        </app-closable-modal-template>
    `,
    styles: [
        `
            img {
                max-height: 100%;
                object-fit: cover;
            }
        `,
    ],
})
export class ImageModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
