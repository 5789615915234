import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReviewBucketRequest } from '../../analytics';
import { ReviewBucketsType } from '../components/reviews-buckets/reviews-buckets.enum';
import { ReviewsSandbox } from './reviews.sandbox';

@Injectable()
export class SsrReviewsSandbox extends ReviewsSandbox {
    public getReviewBuckets(): Observable<ReviewBucketRequest> {
        return of(null);
    }
    public getReviewBucketsType(): Observable<ReviewBucketsType> {
        return of(ReviewBucketsType.Default);
    }
}
