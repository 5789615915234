import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NotificationSandbox } from '@smooved/ui';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { MoveService } from '../../move/services/move.service';
import { NotificationLabel } from '../../notification/enums/notification-label.enum';
import { AppUiSandbox } from '../../ui/sandboxes/ui.sandbox';
import { AgreementService } from '../services/agreement.service';
import {
    AgreementsActionTypes,
    CreateAgreementByMoveId,
    CreateAgreementByMoveIdFailure,
    CreateAgreementByMoveIdSuccess,
} from './agreement.actions';

@Injectable()
export class AgreementEffects {
    constructor(
        private actions$: Actions,
        private moveService: MoveService,
        private uiSandbox: AppUiSandbox,
        private notificationSandbox: NotificationSandbox,
        private agreementService: AgreementService
    ) {}

    @Effect()
    public createByMoveId$ = this.actions$.pipe(
        ofType<CreateAgreementByMoveId>(AgreementsActionTypes.CreateAgreementByMoveId),
        tap((_) => this.uiSandbox.showLoadingOverlay()),
        mergeMap((action) => {
            return this.agreementService.createByMoveId(action.payload.moveId, action.payload.sendInvite, action.payload.onlyPrint).pipe(
                map(
                    (response) =>
                        new CreateAgreementByMoveIdSuccess({
                            url: response.url,
                            sendInvite: action.payload.sendInvite,
                        })
                ),
                catchError((errorResponse) => of(new CreateAgreementByMoveIdFailure()))
            );
        })
    );

    @Effect({
        dispatch: false,
    })
    public createByMoveIdSuccess$ = this.actions$.pipe(
        ofType<CreateAgreementByMoveIdSuccess>(AgreementsActionTypes.CreateAgreementByMoveIdSuccess),
        tap((action) => {
            this.uiSandbox.hideLoadingOverlay();

            if (!!action.payload && !!action.payload.url) {
                window.open(action.payload.url);
                return;
            }

            if (action.payload.sendInvite) {
                this.notificationSandbox.success(NotificationLabel.CreateAgreementsInviteSuccess);
            } else {
                this.notificationSandbox.success(NotificationLabel.CreateAgreementsPdfSuccess);
            }
        })
    );

    @Effect({
        dispatch: false,
    })
    public createByMoveIdFailure$ = this.actions$.pipe(
        ofType<CreateAgreementByMoveIdFailure>(AgreementsActionTypes.CreateAgreementByMoveIdFailure),
        tap((_) => {
            this.uiSandbox.hideLoadingOverlay();
        })
    );
}
