import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { partnerRegisterCompleteRouting, partnerRegisterContactDetailsRouting } from '@app/authentication/constants/routing.constants';
import { confirmPasswordValidator } from '@app/authentication/validators/confirm-password.validator';
import { UserSandbox } from '@app/user/sandboxes/user.sandbox';
import * as partnerRegisterConstants from '../../constants/partner-register.constants';

@Component({
    selector: 'app-partner-register-password-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'AUTHENTICATION.PARTNER.REGISTER.PASSWORD.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">
                        {{
                            'STEP_INDICATION'
                                | translate
                                    : {
                                          start: partnerRegisterConstants.passwordStep,
                                          end: partnerRegisterConstants.stepsTotal
                                      }
                        }}
                    </p>

                    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit()">
                        <app-password-input
                            formControlName="password"
                            [label]="'AUTHENTICATION.PARTNER.REGISTER.PASSWORD.PASSWORD' | translate"
                        ></app-password-input>

                        <app-password-input
                            formControlName="confirmPassword"
                            [hasMargin]="false"
                            [hasMarginDouble]="true"
                            [label]="'AUTHENTICATION.PARTNER.REGISTER.PASSWORD.CONFIRM_PASSWORD' | translate"
                        ></app-password-input>

                        <p *ngIf="form?.hasError('confirmPassword') && submitted" class="u-color-error u-font-size-small u-margin-bottom">
                            {{ 'AUTHENTICATION.PARTNER.REGISTER.PASSWORD.CONFIRM_PASSWORD_ERROR' | translate }}
                        </p>

                        <app-previous-submit (previous)="goToPrevious()" submitTranslationLabel="SUBMIT"></app-previous-submit>
                    </form>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [
        `
            :host {
                display: block;
                width: 100%;
            }
        `,
    ],
})
export class PartnerRegisterPasswordContainer {
    public partnerRegisterConstants = partnerRegisterConstants;

    public form: UntypedFormGroup = this.formBuilder.group(
        {
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
        },
        {
            validator: confirmPasswordValidator,
        }
    );

    public submitted: boolean;

    constructor(private formBuilder: UntypedFormBuilder, private userSandbox: UserSandbox, private router: Router) {}

    public onSubmit(): void {
        this.submitted = true;
        if (this.form.valid) {
            const { password, confirmPassword } = this.form.getRawValue();
            this.userSandbox.submitInviteData(password, confirmPassword).subscribe(this.handleSubmitInviteData);
        }
    }

    private handleSubmitInviteData = (): void => {
        void this.router.navigate(partnerRegisterCompleteRouting);
    };

    public goToPrevious(): void {
        void this.router.navigate(partnerRegisterContactDetailsRouting);
    }
}
