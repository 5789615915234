import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { crmClientsToObject } from '@app/client/constants/client.constants';
import { RealEstateAgentSandbox } from '@app/real-estate-agent/sandboxes/real-estate-agent.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { mergeMap } from 'rxjs/operators';

@Component({
    selector: 'app-real-estate-agent-register-password-container',
    template: `
        <app-card-container class="u-flex-row u-flex-justify-content-center">
            <div class="u-container">
                <app-card class="u-flex-column u-flex-align-items-start">
                    <h3>{{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.PASSWORD.TITLE' | translate }}</h3>
                    <p class="u-color-muted u-margin-bottom-triple">
                        {{
                            'STEP_INDICATION'
                                | translate
                                    : {
                                          start: 3,
                                          end: 3
                                      }
                        }}
                    </p>

                    <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
                        <app-password-input
                            formControlName="password"
                            [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.PASSWORD.PASSWORD' | translate"
                        ></app-password-input>

                        <app-password-input
                            formControlName="confirmPassword"
                            [hasMargin]="false"
                            [hasMarginDouble]="true"
                            [label]="'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.PASSWORD.CONFIRM_PASSWORD' | translate"
                        ></app-password-input>

                        <p *ngIf="form?.hasError('confirmPassword') && submitted" class="u-color-error u-font-size-small u-margin-bottom">
                            {{ 'AUTHENTICATION.REAL_ESTATE_AGENT.REGISTER.INVITE.PASSWORD.CONFIRM_PASSWORD_ERROR' | translate }}
                        </p>

                        <app-previous-submit (previous)="goToPrevious()"></app-previous-submit>
                    </form>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [
        `
            :host {
                display: block;
                width: 100%;
            }
        `,
    ],
})
export class RealEstateAgentRegisterPasswordContainer implements OnInit {
    public form: UntypedFormGroup = this.formBuilder.group(
        {
            password: [null, [Validators.required]],
            confirmPassword: [null, [Validators.required]],
        },
        {
            validator: (formGroup: UntypedFormGroup) => {
                const password = formGroup.controls.password.value;
                const confirmPassword = formGroup.controls.confirmPassword.value;

                if (!password || !confirmPassword) {
                    return null;
                }
                return password === confirmPassword
                    ? null
                    : {
                          confirmPassword: true,
                      };
            },
        }
    );

    public submitted: boolean;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private realEstateAgentSandbox: RealEstateAgentSandbox,
        private router: Router,
        private uiSandbox: AppUiSandbox
    ) {}

    public ngOnInit(): void {}

    public onSubmit(event): void {
        this.submitted = true;
        if (this.form.valid) {
            this.uiSandbox.showLoadingOverlay();
            this.realEstateAgentSandbox.realEstateAgentRegisterDataOnce$
                .pipe(
                    mergeMap((realEstateAgentRegisteredData) => {
                        const { email, firstName, lastName, bivCode, phoneNumber, language } = realEstateAgentRegisteredData;

                        const request: any = {
                            email,
                            firstName,
                            lastName,
                            bivCode,
                            phoneNumber,
                            password: this.passwordFormControl().value,
                            confirmPassword: this.confirmPasswordFormControl().value,
                            ...crmClientsToObject((value: string) => realEstateAgentRegisteredData[value]),
                        };

                        if (language) request.language = language;

                        return realEstateAgentRegisteredData?.confirmationToken
                            ? this.realEstateAgentSandbox.confirmInvite(realEstateAgentRegisteredData?.confirmationToken, request)
                            : this.realEstateAgentSandbox.register(request);
                    })
                )
                .subscribe(
                    (tokenResponse) => {
                        this.uiSandbox.hideLoadingOverlay();
                        this.router
                            .navigate([tokenResponse ? '../complete' : '../success'], {
                                relativeTo: this.route,
                            })
                            .then();
                    },
                    () => {
                        this.uiSandbox.hideLoadingOverlay();
                    }
                );
        }
    }

    public goToPrevious(): void {
        this.router
            .navigate(['../other-details'], {
                relativeTo: this.route,
            })
            .then();
    }

    private passwordFormControl(): AbstractControl {
        return this.form?.get('password');
    }

    private confirmPasswordFormControl(): AbstractControl {
        return this.form?.get('confirmPassword');
    }
}
