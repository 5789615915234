import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Host,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
    SkipSelf,
    ViewChild,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTimeInputOptions } from '@app/form/components/date-time-input/date-time-input-options';
import { defaultOptions, defaultTime } from '@app/form/components/date-time-input/date-time-input.constants';
import { SimpleChangesUtils } from '@smooved/core';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-date-time-input',
    template: `
        <app-label-container [required]="required" [id]="id" [label]="label" [hasMargin]="hasMargin" [hasMarginDouble]="hasMarginDouble">
            <mat-form-field floatLabel="never" appearance="outline">
                <input
                    [id]="id"
                    matInput
                    #input
                    [(ngModel)]="innerModel"
                    (ngModelChange)="onModelChange()"
                    [ngxMatDatetimePicker]="picker"
                    [placeholder]="placeholder"
                    [errorStateMatcher]="errorStateMatcher"
                    [min]="minDate"
                    [max]="maxDate"
                    [disabled]="innerDisabled"
                    [ngxMatDatetimePickerFilter]="datepickerFilter"
                    autocomplete="smooved"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                    #picker
                    [showSpinners]="dateTimeOptions.showSpinners"
                    [showSeconds]="dateTimeOptions.showSeconds"
                    [stepHour]="dateTimeOptions.stepHour"
                    [stepMinute]="dateTimeOptions.stepMinute"
                    [stepSecond]="dateTimeOptions.stepSecond"
                    [touchUi]="dateTimeOptions.touchUi"
                    [disableMinute]="dateTimeOptions.disableMinute"
                    [hideTime]="dateTimeOptions.hideTime"
                    [defaultTime]="initialTime"
                >
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </app-label-container>
    `,
    styleUrls: ['./date-time-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeInputComponent),
            multi: true,
        },
    ],
})
export class DateTimeInputComponent extends BaseInput implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    @Input() public id: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public formControlName: string;
    @Input() public autoFocus = false;
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;
    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public datepickerFilter: (date: Date) => boolean;
    @Input() public options: DateTimeInputOptions;

    public initialTime = defaultTime;
    public dateTimeOptions = defaultOptions;

    public innerModel: Date;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer) {
        super(controlContainer);
    }

    public ngOnChanges({ options }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(options)) {
            this.dateTimeOptions = { ...defaultOptions, ...options };
        }
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }

    public writeValue(value: Date): void {
        this.innerModel = value;
    }

    public onModelChange() {
        this.propagateChange(this.innerModel);
    }
}
